.container{
    display: flex;
    justify-content: center;
    width: 60%;
}

@media (max-width: 770px) {
    .container {
      width: 100%;
    }
  
  }