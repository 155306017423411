body {
  background-color: rgb(250, 250, 250);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  width: 370px;
  margin-top: 20px;
}
.dataRow {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    align-items: flex-start;
}

@media (max-width: 770px) {
  .container {
    margin: 0 10%;
  }

  .image {
    width: 100%;
  }
}
